<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>템플릿 관리</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-card">
            <div class="jh-card-header has-tab">
                <v-tabs v-model="tab1" slider-size="4">
                    <v-tab key="tab1_1">SMS 템플릿</v-tab>
                    <v-tab key="tab1_2">이메일 템플릿</v-tab>
                </v-tabs>
            </div>
            <div class="jh-card-body">
                <v-tabs-items v-model="tab1">
                    <v-tab-item key="tab1_1">
                        <div class="tab-contnets">
                            <div class="jh-search-form">
                                <table>
                                    <colgroup>
                                        <col width="40px">
                                        <col width="300px">
                                        <col width="80px">
                                        <col width="100px">
                                        <col>
                                    </colgroup>
                                    <tr>
                                        <th><label>제목</label></th>
                                        <td>
                                            <input type="text" class="jh-form" v-model="searchValue_tab1.TITLE" @keypress.enter="search_tab1">
                                        </td>
                                        <th><label>사용유무</label></th>
                                        <td>
                                            <v-select class="jh-form" :items="usedCodeList" v-model="searchValue_tab1.USE_YN" />
                                        </td>
                                        <td class="has-search">
                                            <v-btn class="jh-btn is-search" @click="search_tab1">조회</v-btn>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="jh-cols">
                                <div>
                                    <div class="jh-ui-header">
                                        <h3>SMS 템플릿 리스트</h3>
                                        <div class="is-right">
                                        </div>
                                    </div>
                                    <data-tables
                                        ref="dataTable1"
                                        :data-table-options="dataTable1Options"
                                        @click:row="onClickRow_dataTable1"
                                        :paginationOptions="paginationOptions_dataTable1"
                                        v-model="selectedRows_dataTable1"
                                    />
                                </div>
                                <div>
                                    <div class="jh-ui-header">
                                        <h3>SMS 템플릿 상세정보</h3>
                                        <div class="is-right">
                                            <v-btn class="jh-btn" @click="reset_tab1">초기화</v-btn>
                                            <v-btn class="jh-btn is-del" v-if="mixin_set_btn($options.name, 'btnSave')" :disabled="!smsTemplateDetail.ID" :loading="isLoading" @click="delete_tab1">삭제</v-btn>
                                            <v-btn class="jh-btn is-main" v-if="mixin_set_btn($options.name, 'btnDelete')" :loading="isLoading" @click="save_tab1">저장</v-btn>
                                        </div>
                                    </div>
                                    <table class="jh-tbl-detail">
                                        <colgroup>
                                            <col width="80px">
                                            <col>
                                            <col width="80px">
                                            <col>
                                            <col width="80px">
                                            <col width="120px">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><label>등록자</label></th>
                                                <td>
                                                    <span class="is-value">{{ smsTemplateDetail.REG_NM }}</span>
                                                </td>
                                                <th><label>등록일시</label></th>
                                                <td colspan="3">
                                                    <span class="is-value">{{ smsTemplateDetail.REG_DTTM }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th><label>수정자</label></th>
                                                <td>
                                                    <span class="is-value">{{ smsTemplateDetail.UPD_NM }}</span>
                                                </td>
                                                <th><label>수정일시</label></th>
                                                <td>
                                                    <span class="is-value">{{ smsTemplateDetail.UPD_DTTM }}</span>
                                                </td>
                                                <th><label>사용유무</label></th>
                                                <td>
                                                    <v-select class="jh-form" :items="filteredUsedCodeList()" placeholder="선택" v-model="smsTemplateDetail.USE_YN" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th><label>제목</label></th>
                                                <td colspan="5">
                                                    <input type="text" class="jh-form" v-model="smsTemplateDetail.TITLE">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th><label>내용</label></th>
                                                <td colspan="5">
                                                    <v-textarea class="jh-form" style="height:475px" v-model="smsTemplateDetail.CONTENT" />
                                                    <div class="jh-txt-counter is-txt-right"><strong :class="smsContentLen > 4000 ? 'is-txt-red': ''">{{smsContentLen | inputNumberFormat}}</strong> / 4,000 byte</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </v-tab-item>

                    <v-tab-item key="tab1_2">
                        <div class="tab-contnets">                            
                            <div class="jh-search-form">
                                <table>
                                    <colgroup>
                                        <col width="40px">
                                        <col width="300px">
                                        <col width="80px">
                                        <col width="100px">
                                        <col>
                                    </colgroup>
                                    <tr>
                                        <th><label>제목</label></th>
                                        <td>
                                            <input type="text" class="jh-form" v-model="searchValue_tab2.TITLE" @keypress.enter="search_tab2">
                                        </td>
                                        <th><label>사용유무</label></th>
                                        <td>
                                            <v-select class="jh-form" :items="usedCodeList" v-model="searchValue_tab2.USE_YN" />
                                        </td>
                                        <td class="has-search">
                                            <v-btn class="jh-btn is-search" @click="search_tab2">조회</v-btn>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="jh-cols">
                                <div>
                                    <div class="jh-ui-header">
                                        <h3>이메일 템플릿 리스트</h3>
                                        <div class="is-right">
                                        </div>
                                    </div>
                                    <data-tables
                                        ref="dataTable2"
                                        :data-table-options="dataTable2Options"
                                        @click:row="onClickRow_dataTable2"
                                        :paginationOptions="paginationOptions_dataTable2"
                                        v-model="selectedRows_dataTable2"
                                    />
                                </div>
                                <div>
                                    <div class="jh-ui-header">
                                        <h3>이메일 템플릿 상세정보</h3>
                                        <div class="is-right">
                                            <v-btn class="jh-btn" @click="reset_tab2">초기화</v-btn>
                                            <v-btn class="jh-btn is-del" v-if="mixin_set_btn($options.name, 'btnSave')" :disabled="!emailTemplateDetail.ID" :loading="isLoading" @click="delete_tab2">삭제</v-btn>
                                            <v-btn class="jh-btn is-main" v-if="mixin_set_btn($options.name, 'btnDelete')" :loading="isLoading" @click="save_tab2">저장</v-btn>
                                        </div>
                                    </div>
                                    <table class="jh-tbl-detail">
                                        <colgroup>
                                            <col width="80px">
                                            <col>
                                            <col width="80px">
                                            <col>
                                            <col width="80px">
                                            <col width="120px">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><label>등록자</label></th>
                                                <td>
                                                    <span class="is-value">{{ emailTemplateDetail.REG_NM }}</span>
                                                </td>
                                                <th><label>등록일시</label></th>
                                                <td colspan="3">
                                                    <span class="is-value">{{ emailTemplateDetail.REG_DTTM }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th><label>수정자</label></th>
                                                <td>
                                                    <span class="is-value">{{ emailTemplateDetail.UPD_NM }}</span>
                                                </td>
                                                <th><label>수정일시</label></th>
                                                <td>
                                                    <span class="is-value">{{ emailTemplateDetail.UPD_DTTM }}</span>
                                                </td>
                                                <th><label>사용유무</label></th>
                                                <td>
                                                    <v-select class="jh-form" :items="filteredUsedCodeList()" placeholder="선택" v-model="emailTemplateDetail.USE_YN" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th><label>제목</label></th>
                                                <td colspan="5">
                                                    <input type="text" class="jh-form" v-model="emailTemplateDetail.TITLE">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="jh-editor is-mt-5">
                                        <ckeditor v-if="lodash.includes($options.name, active_tab_id)" v-model="emailTemplateDetail.CONTENT" :config="editorConfig"></ckeditor>
                                        <div class="jh-txt-counter is-txt-right"><strong :class="emailContentLen > 5080 ? 'is-txt-red': ''">{{emailContentLen | inputNumberFormat}}</strong> / 5,080 byte</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-tab-item>
                </v-tabs-items>          
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

import iconv from "iconv-lite";
import he from "he";

export default {
    name: "MENU_E030301", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        dataTables
    },
    data() {
        return {
            lodash: _,

            isLoading: false,

            tab1: "tab1_1",

            usedCodeList: [
                { text: "전체", value: null },
                { text: "사용", value: "Y" },
                { text: "사용안함", value: "N" },
            ],

            searchValue_tab1: {
                CHNL_KIND: "SMS",
                TITLE: null,
                USE_YN: null
            },
            searchValue_tab2: {
                CHNL_KIND: "EMAIL",
                TITLE: null,
                USE_YN: null
            },

            headers: [
                { text: "순번", value: "ROWNUM", align: "center", width: "50px", sortable: false },
                { text: "제목", value: "TITLE" },
                { text: "수정자", value: "UPD_NM",  align: "center", width: "100px" },
                { text: "수정일시", value: "UPD_DTTM",  align: "center", width: "150px" },
                { text: "사용유무", value: "USE_YN",  align: "center", width: "80px" },
            ],
            dataTable1Options: {
                fixedHeader: true,
                height: "575",
                hideDefaultFooter: true,
                itemKey: "ID",
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,

                load: this.getList_tab1,
            },
            paginationOptions_dataTable1: {
                totalVisible: 10
            },
            dataTable2Options: {
                fixedHeader: true,
                height: "575",
                hideDefaultFooter: true,
                itemKey: "ID",
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,

                load: this.getList_tab2,
            },
            paginationOptions_dataTable2: {
                totalVisible: 10
            },

            editorConfig: {
                extraPlugins: "font, colorbutton, colordialog, openlink, spacingsliders",
                height: "371px",
                toolbar: [
                    { name: "clipboard", items: [ "Cut", "Copy", "Paste", "-", "Undo", "Redo" ] },//, "PasteText", "PasteFromWord"
                    { name: "editing" }, //, items: [ "Scayt" ]
                    { name: "links", items: [ "Link", "Unlink" ] },//, "Anchor"
                    { name: "insert", items: [ "Image", "Table", "HorizontalRule", "SpecialChar" ] },
                    { name: "forms" },
                    { name: "styles", items: [ "Font", "FontSize" ] },
                    { name: "others", items: [ "-" ] },
                    "/",
                    { name: "styles", items: [ "Styles", "Format" ] },
                    { name: "colors", items: [ "TextColor", "BGColor", "spacingsliders" ] },
                    { name: "basicstyles", items: [ "Bold", "Italic", "Strike", "-", "RemoveFormat" ] },
                    { name: "paragraph", items: [ "NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote" ] },
                    { name: "document", items: [ "Source" ] },
                    { name: "tools", items: [ "Maximize" ] },
                    { name: "about", items: [] }
                ],
                toolbarCanCollapse:  true,
            },

            requestData: {
                headers: {
                    SERVICE: "message.template",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            },
            requestData_tab1: {},
            requestData_tab2: {},

            selectedKey_dataTable1: null,
            selectedRows_dataTable1: [],
            selectedKey_dataTable2: null,
            selectedRows_dataTable2: [],

            smsTemplateDetail: {
                ID: null,
                CHNL_KIND: "SMS",
                TITLE: "",
                CONTENT: "",
                USE_YN: null,
                REG_ID: null,
                REG_NM: null,
                REG_DTTM: null,
                UPD_ID: null,
                UPD_NM: null,
                UPD_DTTM: null
            },
            emailTemplateDetail: {
                ID: null,
                CHNL_KIND: "EMAIL",
                TITLE: "",
                CONTENT: "",
                USE_YN: null,
                REG_ID: null,
                REG_NM: null,
                REG_DTTM: null,
                UPD_ID: null,
                UPD_NM: null,
                UPD_DTTM: null
            },
        };
    },
    filters: {
        inputNumberFormat(v){
            return String(v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    },
    computed: {
        ...mapGetters({
            active_tab_id: "commonStore/GE_COMMON_ACTIVE_TAB_ID",
        }),
        dataTable1Instance: function() {
            return this.$refs.dataTable1;
        },
        dataTable2Instance: function() {
            return this.$refs.dataTable2;
        },
        smsTitleLen: function() {
            return iconv.encode(he.decode(this.smsTemplateDetail.TITLE), "euc-kr").length;
        },
        smsContentLen: function() {
            return iconv.encode(he.decode(this.smsTemplateDetail.CONTENT), "euc-kr").length;
        },
        emailTitleLen: function() {
            return iconv.encode(he.decode(this.emailTemplateDetail.TITLE), "euc-kr").length;
        },
        emailContentLen: function() {
            // TR전송할 때와 동일하게 개행문자, 탭을 제거하고 사이즈 체크
            let content = _.replace(this.emailTemplateDetail.CONTENT, /\n/gi, "");
            content = _.replace(content, /\t/gi, "");
            return iconv.encode(this.restoreXSS_CKeditor(content), "euc-kr").length;
        }
    },
    methods: {
        init: async function() {
            // 헤더 초기화 및 세팅
            this.setDataTableParams_tab1();
            this.setDataTableParams_tab2();
        },

        setDataTableParams_tab1: function() {
            this.requestData_tab1 = _.cloneDeep(this.requestData);
            // header 세팅
            this.requestData_tab1.headers["URL"] = "/api/message/template/list";
            this.requestData_tab1.headers["METHOD"] = "list";
            this.requestData_tab1.headers["ASYNC"] = false;

            // sendData 세팅
            this.requestData_tab1.sendData = this.searchValue_tab1;
            // this.requestData_tab1.sendData["USER_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
        },
        getList_tab1: async function(loadOptions) {
            // 페이징 정보 세팅
            this.requestData_tab1.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            this.requestData_tab1.headers["PAGES_CNT"] = loadOptions.page;
            if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
                this.requestData_tab1.sendData["SORT_ORDR"] = loadOptions.sortBy[0];
            else this.requestData_tab1.sendData["SORT_ORDR"] = undefined;
            if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
                this.requestData_tab1.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? "desc" : "asc";
            else this.requestData_tab1.sendData["SORT_DRCT"] = undefined;

            return this.common_postCall(this.requestData_tab1).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                _.each(data, (item) => {
                    item.TITLE = this.restoreXSS(item.TITLE);
                    // 화면에서는 줄바꿈 문자를 \n으로 처리하지만, 서버에서는 \r\n으로 처리하므로 서버에서 가져온 문자열의 줄바꿈 문자를 모두 변경
                    item.CONTENT = _.replace(item.CONTENT, /\r\n/gi, "\n");
                    item.CONTENT = this.restoreXSS(item.CONTENT);
                    item.REG_DTTM = item.REG_DTTM.substr(0, 19);
                    item.UPD_DTTM = item.UPD_DTTM.substr(0, 19);
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT,
                };
            });
        },
        search_tab1: function() {
            this.setDataTableParams_tab1();

            if (this.dataTable1Options.page > 1) this.dataTable1Options.page = 1;
            else this.dataTable1Instance.loadData();
        },
        reset_tab1: function() {
            // 선택된 열 초기화
            this.selectedKey_dataTable1 = null;
            this.selectedRows_dataTable1 = [];
            // 상세 정보 초기화
            this.smsTemplateDetail = {
                ID: null,
                CHNL_KIND: "SMS",
                TITLE: "",
                CONTENT: "",
                USE_YN: null,
                REG_ID: null,
                REG_NM: null,
                REG_DTTM: null,
                UPD_ID: null,
                UPD_NM: null,
                UPD_DTTM: null
            };
        },
        save_tab1: function() {
            if (!this.smsTemplateDetail.TITLE) {
                this.common_alert("제목을 입력해야 합니다.", "noti");
            }
            else if (this.smsTitleLen > 150) {
                this.common_alert(`제목은 150Byte를 초과할 수 없습니다.\n현재 ${this.smsTitleLen}Byte 입력하셨습니다.`, "noti");
            }
            else if (!this.smsTemplateDetail.CONTENT) {
                this.common_alert("내용을 입력해야 합니다.", "noti");
            }
            else if (this.smsContentLen > 4000) {
                this.common_alert(`내용은 4,000Byte를 초과할 수 없습니다.\n현재 ${this.smsContentLen}Byte 입력하셨습니다.`, "noti");
            }
            else {
                this.common_confirm('저장하시겠습니까?', this.saveSmsTemplate, null, null, null, 'chk');
            }
        },
        saveSmsTemplate: function() {
            this.isLoading = true;

            // header 세팅
            let requestData = {
                headers: {
                    ASYNC: false,
                    METHOD: "save",
                    SERVICE: "",
                    TYPE: "BIZ_SERVICE",
                    URL: "/api/message/template/save"
                },
                sendData: {},
            };

            // sendData 세팅
            requestData.sendData = this.smsTemplateDetail;
            requestData.sendData.USER_ID = this.$store.getters["userStore/GE_USER_ROLE"].userId;

            this.common_postCall(requestData).then(res => {
                if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }
                let data = res.DATA[0];
                data.TITLE = this.restoreXSS(data.TITLE);
                // 화면에서는 줄바꿈 문자를 \n으로 처리하지만, 서버에서는 \r\n으로 처리하므로 서버에서 가져온 문자열의 줄바꿈 문자를 모두 변경
                data.CONTENT = _.replace(data.CONTENT, /\r\n/gi, "\n");
                data.CONTENT = this.restoreXSS(data.CONTENT);
                data.REG_DTTM = data.REG_DTTM.substr(0, 19);
                data.UPD_DTTM = data.UPD_DTTM.substr(0, 19);
                this.selectedRows_dataTable1 = res.DATA;
                this.smsTemplateDetail = data;
                this.common_alert("정상 처리되었습니다.", "done");
                this.search_tab1();
            }).finally(() => {
                this.isLoading = false;
            });
        },
        delete_tab1: function() {
            this.common_confirm('삭제하시겠습니까?', this.deleteSmsTemplate, null, null, null, 'chk');
        },
        deleteSmsTemplate: function() {
            this.isLoading = true;

            // header 세팅
            let requestData = {
                headers: {
                    ASYNC: false,
                    METHOD: "delete",
                    SERVICE: "",
                    TYPE: "BIZ_SERVICE",
                    URL: "/api/message/template/delete"
                },
                sendData: {},
            };

            // sendData 세팅
            requestData.sendData.ID = this.smsTemplateDetail.ID;

            this.common_postCall(requestData).then(res => {
                if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }
                this.reset_tab1();
                this.common_alert("정상 처리되었습니다.", "done");
                this.search_tab1();
            }).finally(() => {
                this.isLoading = false;
            });
        },
        
        setDataTableParams_tab2: function() {
            this.requestData_tab2 = _.cloneDeep(this.requestData);
            // header 세팅
            this.requestData_tab2.headers["URL"] = "/api/message/template/list";
            this.requestData_tab2.headers["METHOD"] = "list";
            this.requestData_tab2.headers["ASYNC"] = false;

            // sendData 세팅
            this.requestData_tab2.sendData = this.searchValue_tab2;
            // this.requestData_tab2.sendData["USER_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
        },
        getList_tab2: async function(loadOptions) {
            // 페이징 정보 세팅
            this.requestData_tab2.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            this.requestData_tab2.headers["PAGES_CNT"] = loadOptions.page;
            if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
                this.requestData_tab2.sendData["SORT_ORDR"] = loadOptions.sortBy[0];
            else this.requestData_tab2.sendData["SORT_ORDR"] = undefined;
            if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
                this.requestData_tab2.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? "desc" : "asc";
            else this.requestData_tab2.sendData["SORT_DRCT"] = undefined;

            return this.common_postCall(this.requestData_tab2).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                _.each(data, (item) => {
                    item.TITLE = this.restoreXSS(item.TITLE);
                    item.CONTENT = this.restoreXSS_CKeditor(item.CONTENT);
                    item.REG_DTTM = item.REG_DTTM.substr(0, 19);
                    item.UPD_DTTM = item.UPD_DTTM.substr(0, 19);
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT,
                };
            });
        },
        search_tab2: function() {
            this.setDataTableParams_tab2();

            if (this.dataTable2Options.page > 1) this.dataTable2Options.page = 1;
            else this.dataTable2Instance.loadData();
        },
        reset_tab2: function() {
            // 선택된 열 초기화
            this.selectedKey_dataTable2 = null;
            this.selectedRows_dataTable2 = [];
            // 상세 정보 초기화
            this.emailTemplateDetail = {
                ID: null,
                CHNL_KIND: "EMAIL",
                TITLE: "",
                CONTENT: "",
                USE_YN: null,
                REG_ID: null,
                REG_NM: null,
                REG_DTTM: null,
                UPD_ID: null,
                UPD_NM: null,
                UPD_DTTM: null
            };
        },
        save_tab2: function() {
            if (!this.emailTemplateDetail.TITLE) {
                this.common_alert("제목을 입력해야 합니다.", "noti");
            }
            else if (this.emailTitleLen > 255) {
                this.common_alert(`제목은 255Byte를 초과할 수 없습니다.\n현재 ${this.emailTitleLen}Byte 입력하셨습니다.`, "noti");
            }
            else if (!this.emailTemplateDetail.CONTENT) {
                this.common_alert("내용을 입력해야 합니다.", "noti");
            }
            else if (this.emailContentLen > 5080) {
                this.common_alert(`내용은 5,080Byte를 초과할 수 없습니다.\n현재 ${this.emailContentLen}Byte 입력하셨습니다.`, "noti");
            }
            else {
                this.common_confirm('저장하시겠습니까?', this.saveEmailTemplate, null, null, null, 'chk');
            }
        },
        saveEmailTemplate: function() {
            this.isLoading = true;

            // header 세팅
            let requestData = {
                headers: {
                    ASYNC: false,
                    METHOD: "save",
                    SERVICE: "",
                    TYPE: "BIZ_SERVICE",
                    URL: "/api/message/template/save"
                },
                sendData: {},
            };

            // sendData 세팅
            // requestData.sendData = this.emailTemplateDetail;
            requestData.sendData = Object.assign({}, this.emailTemplateDetail, { CONTENT: this.restoreXSS_CKeditorDec(this.emailTemplateDetail.CONTENT) });
            requestData.sendData.USER_ID = this.$store.getters["userStore/GE_USER_ROLE"].userId;

            this.common_postCall(requestData).then(res => {
                if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }
                let data = res.DATA[0];
                data.TITLE = this.restoreXSS(data.TITLE);
                data.CONTENT = this.restoreXSS_CKeditor(data.CONTENT);
                data.REG_DTTM = data.REG_DTTM.substr(0, 19);
                data.UPD_DTTM = data.UPD_DTTM.substr(0, 19);
                this.selectedRows_dataTable2 = res.DATA;
                this.emailTemplateDetail = data;
                this.common_alert("정상 처리되었습니다.", "done");
                this.search_tab2();
            }).finally(() => {
                this.isLoading = false;
            });
        },
        delete_tab2: function() {
            this.common_confirm('삭제하시겠습니까?', this.deleteEmailTemplate, null, null, null, 'chk');
        },
        deleteEmailTemplate: function() {
            this.isLoading = true;

            // header 세팅
            let requestData = {
                headers: {
                    ASYNC: false,
                    METHOD: "delete",
                    SERVICE: "",
                    TYPE: "BIZ_SERVICE",
                    URL: "/api/message/template/delete"
                },
                sendData: {},
            };

            // sendData 세팅
            requestData.sendData.ID = this.emailTemplateDetail.ID;

            this.common_postCall(requestData).then(res => {
                if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }
                this.reset_tab2();
                this.common_alert("정상 처리되었습니다.", "done");
                this.search_tab2();
            }).finally(() => {
                this.isLoading = false;
            });
        },

        onClickRow_dataTable1: function(item, row) { 
            if (this.selectedKey_dataTable1 === item.ID) {
                row.select(false);
                this.selectedKey_dataTable1 = null;
                this.smsTemplateDetail = {
                    ID: null,
                    CHNL_KIND: "SMS",
                    TITLE: "",
                    CONTENT: "",
                    USE_YN: null,
                    REG_ID: null,
                    REG_NM: null,
                    REG_DTTM: null,
                    UPD_ID: null,
                    UPD_NM: null,
                    UPD_DTTM: null
                };
            } else {
                row.select(true);
                this.selectedKey_dataTable1 = item.ID;
                this.smsTemplateDetail = Object.assign(this.smsTemplateDetail, item);
            }
        },
        onClickRow_dataTable2: function(item, row) { 
            if (this.selectedKey_dataTable2 === item.ID) {
                row.select(false);
                this.selectedKey_dataTable2 = null;
                this.emailTemplateDetail = {
                    ID: null,
                    CHNL_KIND: "EMAIL",
                    TITLE: "",
                    CONTENT: "",
                    USE_YN: null,
                    REG_ID: null,
                    REG_NM: null,
                    REG_DTTM: null,
                    UPD_ID: null,
                    UPD_NM: null,
                    UPD_DTTM: null
                };
            } else {
                row.select(true);
                this.selectedKey_dataTable2 = item.ID;
                this.emailTemplateDetail = Object.assign(this.emailTemplateDetail, item);
            }
        },

        filteredUsedCodeList: function() {
            return _.filter(this.usedCodeList, (item) => {
                return item.value;
            });
        },
    },
    created() {
        this.dataTable1Options.headers = this.headers;
        this.dataTable2Options.headers = this.headers;
    },
    mounted() {
        this.init();
    },
    watch: {}
};
</script>

<style></style>